window.addEventListener("load", function(){
	window.cookieconsent.initialise({
		"palette": {
			"popup": {
				"background": "#edeff5",
				"text": "#838391"
			},
			"button": {
				"background": "#4b81e8"
			}
		},
		"theme": "edgeless",
		"content": {
			"message": "Diese Seite verwendet Cookies. Wenn Sie fortfahren, stimmen Sie unserer Verwendung von Cookies zu. Erfahren Sie mehr in unseren ",
			"dismiss": "Annehmen",
			"link": "Rechtlichen Hinweisen.",
			"href": "/datenschutz"
		}
	})
});

jQuery(document).ready(function($) {
   

	//Fix jumping on sticky
	$("body").css("padding-top", $(".c-header").height());

	//createSticky($(".c-header"));


	//$('.c-gallery').lightGallery({
	//	selector: '.lightbox'
	//});


	$("#js__c-header__navtoggle").click(function() {
		$(".c-navigation").fadeToggle();
		$(".c-navicon", this).toggleClass("c-navicon--active");
	});
	
	
	$('.c-navigation').onePageNav({
		currentClass: 'c-navigation__item-level1--active',
		changeHash: false,
		scrollSpeed: 750,
		scrollThreshold: 0.5,
		filter: '',
		easing: 'swing',
		begin: function() {
			//I get fired when the animation is starting
		},
		end: function() {
			//I get fired when the animation is ending
		},
		scrollChange: function($currentListItem) {
			//I get fired when you enter a section and I pass the list item of the section
		}
		
	});

/*
	$(function() {
	  $('a[href^="#"]').click(function() {

	    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
	      var target = $(this.hash);
	      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
	      if (target.length) {
	        $('html,body').animate({ scrollTop: target.offset().top -50 }, 500);
	        return false;
	      }
	    }
	  });
	});*/
	
	// form validation
    $('.booking-form').validate({
        rules: {
            name: 'required',
            mail: 'required',
            datenschutz:'required'
        },
        messages: {
            name: 'Bitte geben Sie Ihren Namen ein.',
            mail: 'Keine gültige E-Mail Adresse.',
            datenschutz: 'Bitte akzeptieren Sie die Datenschutzerklärung.'
        }
    });
    
    
    //$('.slider').bxSlider();
    
    
    
	$('.nivoSlider').nivoSlider({
		effect: 'fade',
		pauseTime: 5000,
		directionNav: true,
		controlNav: true
	});

	
});